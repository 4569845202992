import React , { useEffect } from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import AboutUsBanner from "../img/about-us.jpg";
import AboutUsBanner01 from "../img/about-us-01.jpg";
import AboutUsBanner02 from "../img/about-us-02.jpg";
import AboutUsBanner03 from "../img/about-us-03.jpg";
//AOS
import AOS from "aos";
import "aos/dist/aos.css";
export default function AboutUs() {

  useEffect(() => {

    AOS.init({
      
    });
    
  }, []);

  useEffect(() => {

    if(AOS){
      AOS.refresh();
    }
    
  });

  return (
    <div>
      <Helmet>
        <title>OIC -About Us</title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta name="title" content="OiC" />
        <meta name="description" content="OiC Apps Website" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta property="og:description" content="OiC Apps Website" />
      </Helmet>
      <Layout>
        <div
          className="bannerImage"
          style={{
            backgroundImage: `url(` + AboutUsBanner + `)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6 bannerTextPadder">
                <h1
                  style={{
                    borderBottom: "2px solid #fff",
                    position: "absolute",
                  }}
                >
                  ABOUT <span>US</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60">
          <div className="container">
            <div className="row" data-aos="fade-left" data-aos-duration="400">
              <div className="col-md-12">
                <h2
                  style={{
                    borderLeft: "3px solid #FF9810",
                    paddingLeft: "10px",
                  }}
                >
                  Company Overview
                </h2>
                <p>
                  OIC is a brand new online platform developed by Adhyas
                  Software, a Chennai-based IT Product startup, for all these
                  stakeholders in the optical industry to interact and do
                  business. In the optical industry, OIC links brands, dealers,
                  wholesalers, and retailers with one another, making doing
                  business simpler.
                  <br></br>
                  <br></br>
                  OIC platform helps optical brands and distributors develop
                  their businesses by allowing customers to securely position
                  orders and driving sales across a multi-vendor platform that
                  is second to none. We've created a number of apps specifically
                  for the optical ecosystem, which we publish in our App Store
                  and make available to Brands, Distributors, and Retailers.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div style={{ padding: "20px 0 60px 0" }}>
          <div style={{ background: "#e3e3e3", paddingTop: "30px" }}>
            <div className="container">
              <div className="row" data-aos="fade-right" data-aos-duration="400">
                <div className="col-md-5">
                  <img src={AboutUsBanner02} className="img-responsive"></img>
                </div>
                <div className="col-md-7">
                  <br></br>
                  <h2
                    style={{
                      borderLeft: "3px solid #FF9810",
                      paddingLeft: "10px",
                    }}
                  >
                    Our Mission
                  </h2>
                  <p>
                    <br></br>
                    <ul className="listStyle">
                      <li>
                        We wholly provide B2B e-commerce, where that connects
                        thebrands/distributors and retailers.
                      </li>
                      <li>
                        {" "}
                        Dazzle clients by doing great things in the eyewear
                        industry that have never beenachieved before. To be a
                        pioneer in the optical era, aspire to the optical
                        business by offering the latest and most innovative
                        technologies for the optical industry.
                      </li>
                      <li>
                        Driven towards helping millions improve their business
                        mission & lead better lives and we believe that your
                        trading experience with OIC should be effective, fast,
                        and simple
                      </li>
                      <li>
                        We believe that your trading experience with OIC should
                        be effective, fast, and simple
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ padding: "20px 0 60px 0" }}>
          <div className="container">
            <div className="row" data-aos="fade-left" data-aos-duration="400"> 
              <div className="col-md-12">
                <h2
                  style={{
                    borderLeft: "3px solid #FF9810",
                    paddingLeft: "10px",
                  }}
                >
                  Founder
                </h2>
                <br></br>
                <p>
                  The idea of OIC is the brainchild of Mr. Vasan Sowrirajan, an
                  alumnus of IIT Madras. He has been a product person throughout
                  having pioneered the concept of a virtual makeover in India.
                  He worked extensively on the beauty industry before shifting
                  focus to the optical industry bringing with him the same zest
                  and energy in addition to years of experience in his previous
                  domain. Mr. Vasan was previously associated with Startup
                  companies and worked in NYSE before moving back to India to
                  begin his entrepreneurial journey. His dream of building a
                  world-class product from India was beginning to materialize.
                  <br></br>
                  <br></br>
                  It was during this period that Mr. Vasan showcased his various
                  products at events and seminars and. He was awarded the winner
                  for Microsoft Bizspark, Enterprise Product of the year, CII
                  emerging entrepreneur, etc.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="blackBackground">
          <section className="container-fluid">
            <div className="row aligner" data-aos="fade-right" data-aos-duration="400">
              <div className="col-md-5 noPadding DesktopOnly">
                <img
                  src={AboutUsBanner03}
                  className="img-responsive"
                ></img>
              </div>
              <div className="col-md-5 paddingLeft">
                <br></br> <br></br>
              <h2
                  style={{
                    borderLeft: "3px solid #FF9810",
                    paddingLeft: "10px", fontWeight:"500"
                  }}
                >
                  When You Work With Us,<br></br>
                  <b>You Are A Part Of The OIC Family</b>

                </h2>
                <br></br>
                <p>
                The OIC Team is made up of committed and passionate individuals who, like the founder, have set out on a journey to produce a world-class product from India. Mr. Suresh Kumar Jayapal, an ex-Adobe executive in charge of production, and Mr. Vinoth Kumar, in charge of marketing, lead a very young and energetic team
                </p>
                <br></br> <br></br>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-6 noPadding mobile-only">
                <img
                  src={AboutUsBanner03}
                  className="img-responsive"
                ></img>
              </div>
            </div>
          </section>
        </div>
       
      </Layout>
    </div>
  );
}
